import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import ResponsiveImage from './Image'




const UseCasesCarousel = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);
    console.log('currentSlide', currentSlide)

  }, [currentSlide]);
  const handleAfterChange = index => {
    setCurrentSlide(index)
  };
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    asNavFor: '.slider-nav',
    beforeChange: function (currentSlide, nextSlide) {
      setCurrentSlide(currentSlide)
    },
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,

        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '10px',
          slidesToShow: 2
        }
      },

    ]
  };
  

  const settingsThumbs = {

    asNavFor: '.slider-for',
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    verticalSwiping: true,
    vertical: true,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: data.length,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: function (currentSlide, nextSlide) {
      setCurrentSlide(currentSlide)
    },
    afterChange: handleAfterChange
  };


  const next = () => {
    slider1.slickNext();
    slider2.slickNext();
  }
  const previous = () => {
    slider1.slickPrev();
    slider2.slickNext();
  }





  return (

    <div className="flex gap-8 md:mt-10">
      <div className="md:w-7/12 w-full xl:mr-14 slick-main">

        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={slider => (setSlider1(slider))}
        >
          {data && data.map((item, index) => {
            return (
              <div className={index === 0 && 'flex gap-5'} key={index}>
                <div className="w-full md:hidden">
                  <h5 className="font-bold text-base ">0{index + 1}</h5>
                  {item.title && <h3 className="font-bold text-base mb-3">{item.title}</h3>}
                </div>
                <div className="border-2 border-solid border-gray-800 w-full ">
                  {item?.image?.data?.attributes?.url && (
                    <ResponsiveImage
                      large={item.image.data.attributes.url}
                      medium={item.image.data.attributes.formats.small.url}
                      small={item.image.data.attributes.formats.thumbnail.url}
                      width={item.image.data.attributes.width}
                      height={item.image.data.attributes.height}
                    />
                  )}
                  {item.description && <p className="px-8 py-4 text-base mb-2 text-gray-500 leading-5">{item.description}</p>}
                </div>
              </div>
            )
          })}

        </Slider>
        <div style={{ textAlign: "center" }}>
          <button className="button" onClick={previous}>
            Previous &nbsp;
          </button>

          {currentSlide + 1} / {data.length}{" "}
          <button className="button" onClick={next}>
           &nbsp; Next
          </button>
        </div>
      </div>
      <div className="md:w-5/12 flex px-3 justify-end slick-thumb hidden md:flex">


        <Slider
          {...settingsThumbs}
          asNavFor={nav1}
          ref={slider => (setSlider2(slider))}>
          {data && data.map((item, index) => {
              console.log('data in file', data)
            return (
              <div className="w-full" key={index}>
                <h5 className="text-gray-400 font-bold text-base">0{index + 1}</h5>
                {item.title && <h3 className="font-bold text-base">{item.title}</h3>}
              </div>
            )
          })}

        </Slider>

      </div>
    </div>
  )
}

export default UseCasesCarousel