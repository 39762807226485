import * as React from 'react';
import { graphql } from 'gatsby'


import Page from '../../components/Page';
import HeroSection from '../../components/AiStore/HeroSection';
import WhyAIStore from '../../components/AiStore/WhyAIStore';
import AccrossIndustries from '../../components/AiStore/AccrossIndustries';

import PlugAndPlaySection from '../../components/AiStore/PlugAndPlay'

import Seo from "../../components/Seo";
const LooprAIStore = (props) => {
  const { attributes } = props.data.strapidata.looprAiStore.data
  const { seo } = attributes
  return (    
    <Page>
       <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        pimage={seo.shareImage.data.attributes.url}
        cpath = {props.location.pathname}
        />
      <HeroSection data={attributes}/>
      <PlugAndPlaySection data={attributes}/>
      <WhyAIStore data={attributes}/>
      <AccrossIndustries data={attributes}/>   
    </Page >
  );
};

export default LooprAIStore;

export const query = graphql`
query LooprAiStoreQuery {
  strapidata {
    looprAiStore {
      data {
        attributes {
          seo {
            metaTitle
            metaDescription
            shareImage {
              data {
                attributes {
                  url
                }
              }
            }
          }
          name
          shortDescription
          buttonText
          buttonLink
          advantages {
            title
            description
            image {
              data {
                attributes {
                  url
                  formats
                  width
                  height
                }
              }
            }
          }
          plugPlayHeading
          plugPlayDescription
          plugPlayItems {
            title
            description
            image {
              data {
                attributes {
                  url
                  formats
                  width
                  height
                }
              }
            }
          }
          whyLooprAIStoreHeading
          whyLooprAIStoreDescription
          whyLooprAIStoreItems {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          acrossIndustriesHeading
          acrossIndustriesItems {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
`
