import * as React from "react"
import ResponsiveImage from '../Image'
const WhyAIStore = ({ data }) => {
  const { whyLooprAIStoreHeading, whyLooprAIStoreDescription, whyLooprAIStoreItems } = data
  return (
    <div className='w-full py-20 md:flex '>
      <div className="max-w-7xl mx-auto px-4 flex lg:flex-row flex-col gap-4">
      <div className='lg:w-1/4 flex'>
        <h2 className='lg:w-48 sm:text-3xl text-2xl font-normal mb-6 '>{whyLooprAIStoreHeading}</h2>
      </div>
      <div className='lg:w-3/4 w-full flex justify-center flex-col px-3'>
        <div className="lg:w-3/4">
        {whyLooprAIStoreDescription}
        </div>
        <div className='svg-width mt-14 w-full '>
        <div className="grid md:grid-cols-2 md:gap-16 gap-8 grid-cols-1">
            {whyLooprAIStoreItems && whyLooprAIStoreItems.map((item, index) => {
              return (
              
                <div className='' key={index}>
                  <div className='mb-2 h-20'>
                  <ResponsiveImage 
                        large={item.image.data.attributes.url}
                        medium={item.image.data.attributes.url}
                        small={item.image.data.attributes.url}
                        width={item.image.data.attributes.width}
                        height={item.image.data.attributes.height}
                    />
                  </div>
                  <div>
                    <h4 className='mb-3 mt-5 text-2xl md:block hidden font-semibold text-gray-400'>0{index+1}</h4>
                    <h5 className='md:text-2xl text-1xl font-medium mb-5'>{item.title}</h5>
                    <p className='text-gray-600 text-base font-normal'>{item.description}</p>
                  </div>
                </div>
               
              )
            })}

          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyAIStore