import * as React from "react"
import { Link } from 'gatsby'
import ResponsiveImage from '../Image'
const HeroSection = ({data}) => {
    const {  name, shortDescription, buttonText, advantages} = data
    return (
        <div className='w-full products loopr-data pt-24 pb-10'>
            <div className='max-w-6xl mx-auto px-4 py-24'>
                <div className='md:w-96'>
                    <h6 className='text-xl text-gray-500 mb-2 capitalize'>Products</h6>
                    <h2 className='md:text-4xl text-2xl font-medium  mb-3'>{name}</h2>
                    <p className='mb-5 md:text-xl text-lg font-normal text-gray-600'>{shortDescription}</p>
                    <Link to="/contact-us" className='flex justify-center justify-items-center content-center w-44 py-3 mb-3 border-2 font-bold border-blue-500 text-blue-500 capitalize text-lg hover:bg-blue-500 hover:text-white'><span>{buttonText}</span></Link>
                </div>

                <div className='md:flex md:justify-between mt-16'>
                    {advantages && advantages.map((item, index)=>{
                        return(
                            <div className='md:mb-0 mb-8'>
                                <ResponsiveImage 
                                        large={item.image.data.attributes.url}
                                        medium={item.image.data.attributes.url}
                                        small={item.image.data.attributes.url}
                                        width={item.image.data.attributes.width}
                                        height={item.image.data.attributes.height}
                                    />
                                <h5 className='text-xl md:w-60 lg:w-52 md:mb-6 w-full mb-3 md:mt-5 mt-3  md:h-14'>{item.title}</h5>
                                <p className='md:w-60 lg:w-48 w-full text-base font-normal text-gray-600'>{item.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HeroSection