import * as React from "react"
import { Link } from 'gatsby'
import ResponsiveImage from '../Image'
const AccrossIndustries = ({ data }) => {
  const { acrossIndustriesHeading, acrossIndustriesItems } = data
  return (
    <div className="w-full bg-gray-200 py-20">
      <div className='max-w-7xl mx-auto px-4'>
        <div className='flex gap-4 lg:flex-row flex-col'>
          <div className='lg:w-1/4 w-full'>
            <h2 className='lg:text-3xl md:text-2xl sm:text-3xl text-xl font-medium text-gray-800 mb-5'>
              {acrossIndustriesHeading}
            </h2>
            <p className='lg:text-xl sm:text-lg text-base font-medium text-gray-500 mb-5 lg:pr-8'>How we are helping businesses transform themselves across different fields and various use-cases.</p>

          </div>


          <div className='lg:w-3/4 w-full flex accross-items justify-between sm:flex-row flex-col xl:gap-20 gap-6'>
            {acrossIndustriesItems && acrossIndustriesItems.map((item, index) => {
              return (
                <div className='inner-items' key={index}>
                  <ResponsiveImage
                    large={item.image.data.attributes.url}
                    medium={item.image.data.attributes.url}
                    small={item.image.data.attributes.url}
                    width={item.image.data.attributes.width}
                    height={item.image.data.attributes.height}
                  />
                  <div className='mt-8'>
                    <div className="index text-gray-400">
                      <strong>0{index + 1}</strong>
                    </div>
                    <div className='flex items-center justify-between'>
                      <h3 className='text-2xl font-normal text-gray-700 my-2'>
                        {item.title}
                      </h3>
                      {item.title==='Retail' && (
                        <Link to="/industries/retail/">
                          <svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                        </Link>
                      )}
                      {item.title==='Enterprise' && (
                        <Link to="/industries/manufacturing/">
                          <svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                        </Link>
                      )}
                      {item.title==='Automotive' && (
                        <Link to="/industries/automotive">
                          <svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                        </Link>
                      )}
                      
                    </div>

                    <p className='text-base font-medium text-gray-600 leading-5'>
                      {item.description}
                    </p>
                  </div>
                </div>


              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccrossIndustries