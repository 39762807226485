import React from 'react';
import UseCasesCarousel from '../UseCasesCarousel';


const PlugAndPlaySection = ({data}) => {
  const { plugPlayHeading, plugPlayDescription, plugPlayItems } = data  
  console.log('plugPlayItems',plugPlayItems)
  return (
    <div className="bg-gray-100 py-20">
    <div className="max-w-7xl mx-auto px-4 flex lg:flex-row flex-col gap-4">
      <div className="lg:w-1/4 w-full md:pr-14">
        <h2 className="text-3xl font-bold mb-10">{plugPlayHeading}</h2>
        <p className="text-base font-normal text-gray-600 mb-10">{plugPlayDescription}</p>
      </div>
      <div className="lg:w-3/4 w-full">
        <UseCasesCarousel data={plugPlayItems}/>
      </div>
    </div>
  </div>
  )
}

export default PlugAndPlaySection